import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Antifragile Design at CocoaHeads MTL",
  "date": "2015-07-30T00:00:00.000Z",
  "published": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1100px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "38.18181818181819%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAIABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAIF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAQD/2gAMAwEAAhADEAAAAd2AKEf/xAAXEAEAAwAAAAAAAAAAAAAAAAAAAhES/9oACAEBAAEFAk7y/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFxAAAwEAAAAAAAAAAAAAAAAAABAiMf/aAAgBAQAGPwInV//EABgQAAMBAQAAAAAAAAAAAAAAAAABESGR/9oACAEBAAE/IeFNEhkVlP/aAAwDAQACAAMAAAAQe9//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPxCn/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGxAAAgMAAwAAAAAAAAAAAAAAAREAITFRgcH/2gAIAQEAAT8QKdMOo04ma9g0KLyf/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "antifragile",
          "title": "antifragile",
          "src": "/static/55106f6371b0b17996e3741a63472bc0/ddced/antifragile.jpg",
          "srcSet": ["/static/55106f6371b0b17996e3741a63472bc0/35f54/antifragile.jpg 275w", "/static/55106f6371b0b17996e3741a63472bc0/d7854/antifragile.jpg 550w", "/static/55106f6371b0b17996e3741a63472bc0/ddced/antifragile.jpg 1100w", "/static/55106f6371b0b17996e3741a63472bc0/670dc/antifragile.jpg 1650w", "/static/55106f6371b0b17996e3741a63472bc0/dc129/antifragile.jpg 2122w"],
          "sizes": "(max-width: 1100px) 100vw, 1100px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`A few people asked me if I was doing my “Antifragile Design” talk somewhere in Montreal. The answer is yes! I’ve been asked by the good people at `}<a parentName="p" {...{
        "href": "http://cocoaheadsmtl.com"
      }}>{`CocoaHeads MTL`}</a>{` to do an adapted version of the talk I wrote for the Shopify UX Summit (post about the whole experience coming soon). I’ll talk about the challenges of designing at scale and how to adopt an antifragile process to be able to solve bigger problems. It’s everything I wish I’d had known when I started.`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.meetup.com/fr/CocoaHeads-Montreal/events/224056239/"
      }}>{`Join us August 11th at McKibbins`}</a>{`! It’s going to be a lot of fun.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      